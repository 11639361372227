import React, { useState } from "react";
import { Form, Button, Alert, InputGroup, Spinner,Modal } from "react-bootstrap";
import { CloudUpload, FileCheck } from "react-bootstrap-icons";
import {handleAssesmeCsvUpload} from "../Util/apiHelper"

const UploadAssesmeCsv = ({show,onHide}) => {
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState("");
  const [msg, setMsg] = useState({ type: "success", text: "" });
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile && uploadedFile.type === "text/csv") {
      setFile(uploadedFile);
    } else {
      setFile(null);
      setFileURL("");
      setMsg({
        type: "danger",
        text: "Invalid file type. Please upload a CSV file.",
      });
    }
  };

  const handleUpload = async() => {
    if (!file) {
      setMsg({
        type: "danger",
        text: "Please select a CSV file before uploading.",
      });
      return;
    }
    setIsUploading(true);
    try {
      const res = await handleAssesmeCsvUpload(file)
      if(res && res?.form_url){
        const resUrl = `${res?.form_url}/?student_name=Student+Name&student_email=Student@Email.com&student_roll=1234&student_exam_id=1111`
        setFileURL(resUrl)
      }
    } catch (error) {
      console.log("error in UploadAssesmeCsv", error)
      setMsg({
        type: "danger",
        text: "Error uploading file. Please try again.",
      })
    }finally{
      setIsUploading(false);
    }
  };

  const handleCopyURL = () => {
    navigator.clipboard.writeText(fileURL);
    setMsg({ type: "success", text: "URL copied to clipboard!" });
  };

  return (
    <>
      <link rel="stylesheet" href="assets/css/style.css" />
  
        <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
        <Modal.Title>Upload Your Assessment CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body className="container">
        {msg?.text && <Alert variant={msg?.type}>{msg?.text ?? ""}</Alert>}

        <Form.Group className="mb-4">
          <InputGroup className="gap-2">
            <Form.Control
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="form-control border-1 rounded-pill shadow-sm flex-grow-1"
              style={{ paddingLeft: 10 }}
            />
            <button
              className="btn btn-primary rounded-pill"
              onClick={handleUpload}
              disabled={!file || isUploading}
            >
              {isUploading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  {/* <CloudUpload className=""/> */}
                  Upload
                </>
              )}
            </button>
          </InputGroup>
        </Form.Group>

        {file && !isUploading && (
          <div className="mt-3 text-center">
            <p>
              <strong>Selected File:</strong> {file.name}
            </p>
          </div>
        )}

        {fileURL && (
          <div className="mt-3">
            <InputGroup className="gap-2">
              <Form.Control
                type="text"
                value={fileURL}
                readOnly
                className="form-control rounded-pill"
              />
              <Button
                variant="success"
                onClick={handleCopyURL}
                className="rounded-pill"
              >
                {/* <FileCheck className="me-2" /> */}
                Copy URL
              </Button>
            </InputGroup>
          </div>
        )}
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
        </Modal>
     
    </>
  );
};

export default UploadAssesmeCsv;


