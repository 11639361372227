import React from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";


const SubReviewModal = ({ open, onClose, data = {} }) => {
  return (
    <>
       <link rel="stylesheet" href="assets/css/SubReviewModal.css" />
      <Modal show={open} onHide={onClose} centered size="lg" className="review-modal">
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>📖 Review Question</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <h5 className="question-text"> Que :- {data?.question ?? "No Question Provided"}</h5>
          <p className="answer-text"><strong><b>Ans :-</b></strong>{data?.answer ?? "No Answer Provided"}</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom" style={{display:"flex", justifyContent:"space-between"}}>
          <Button variant="outline-secondary" onClick={onClose} className="close-btn">
            Close
          </Button>
          <Button  className="auto-grade-btn" disabled>
            Auto Grade
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubReviewModal;
