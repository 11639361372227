import React, { useEffect, useState } from "react";
import {
  getFromSessionStorage,
  SESSION_STORAGE_KEYS,
} from "../Util/sessionStorage";
import NewLogin from "../Auth/NewLogin";
import { useHistory } from "react-router-dom";
import SubReviewModal from "../components/subjectiveReviewModal";

const Review = () => {
  const history = useHistory();
  const isLoggedIn = getFromSessionStorage(SESSION_STORAGE_KEYS.user);
  const result = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.result)) || {};
  const [user, setUser] = useState(isLoggedIn || {});
  const [data, setData] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [openModal,setOpenModal]=useState(false)
  const [subJectiveData,setSubjectiveData]=useState({})

  const toggleMenu = () => setShowMenu(!showMenu);
  const usershow = showMenu ? "show" : "";
  const url = `https://assessme.monitorexam.com/api/review_questions/${result?.result ?? ""}/`;

  const getReviewData = async () => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setData(data);
      return data;
    } catch (error) {
      console.error("Error fetching review data:", error);
      return null;
    }
  };

  useEffect(() => {
    getReviewData();
  }, []);

  const handleMarksChange = (index, newMarks) => {

    setData((prevData) => {
      const updatedQuestions = [...prevData.questions]; 
      const question = updatedQuestions[index];
      const totalMarks = parseFloat(question.total_marks)
      const newMarksNum = parseFloat(newMarks)

            if (!isNaN(newMarksNum) && newMarksNum > totalMarks) {
              setInputErrors((prev) => ({
                ...prev,
                [index]: `Given marks cannot be more than total marks (${totalMarks}).`,
              }));
              return prevData;
            } else {
              setInputErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[index];
                return newErrors;
              });
            }
      question.given_marks = newMarks; 
      return { ...prevData, questions: updatedQuestions };
    });
  };

  const handleUpdateMarks = async () => {
    try {
      const marksData = data?.questions?.reduce((acc, item, index) => {
        acc[`given_marks_${index + 1}`] = item.given_marks;
        return acc;
      }, {});
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(marksData),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Marks updated successfully:", responseData);
      alert("Marks updated successfully")
    } catch (error) {
      console.log("Error updating marks:", error);
      alert("Something went wrong! Error updating marks")
    }
  };
  

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    history.replace({
      pathname: "/newlogin",
      state: {
        from: "",
      },
    });
  };

  const handelOpenModal=(item)=>{
    setOpenModal(true);
    setSubjectiveData({
      question:item?.question ?? "",
      answer:item?.user_response ?? ""
    })
  }

  if (!isLoggedIn) {
    return <NewLogin />;
  }

  return (
    <>
      <meta charSet="utf-8" />
      <meta name="author" content="Softnio" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="@@page-description" />
      {/* Fav Icon */}
      <link rel="shortcut icon" href="images/favicon.png" />
      {/* Page Title */}
      <title>Dashboard | Monitor Exam</title>
      {/* StyleSheets */}
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="stylesheet" href="assets/css/custom.css" />
      <link
        id="skin-default"
        rel="stylesheet"
        href="assets/css/theme.css?ver=1.2.0"
      />

      <div className="nk-app-root">
        <div className="nk-main">
          <div className="nk-wrap">
            <div className="nk-header nk-header-fixed is-light">
              <div className="container-lg wide-xl">
                <div className="nk-header-wrap">
                  <div className="nk-header-brand">
                    <a href="#" className="logo-link">
                      <img
                        className="logo-dark logo-img"
                        src="images/monitor-exam-logo.png"
                        srcSet="images/monitor-exam-logo-2x.png 2x"
                        alt="logo-dark"
                      />
                    </a>
                  </div>
                  <div className="nk-header-tools">
                    <ul className="nk-quick-nav">
                      <li className={"dropdown user-dropdown " + usershow}>
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div onClick={toggleMenu} className="user-toggle">
                            <div className="user-avatar sm">
                              <img
                                src={
                                  "https://d1bumj8k6ax755.cloudfront.net/uploads/"
                                  // user?.reg_pic?.split("'")[1]
                                }
                                className="regpic"
                                alt="reg"
                                style={{ transform: "scale(0.5)", left: "6px" }}
                              />
                              <em className="icon ni ni-user-alt" />
                            </div>
                            <div className="user-name dropdown-indicator d-none d-sm-block">
                              {user.first_name} {user.last_name}
                            </div>
                          </div>
                        </a>
                        <div
                          className={
                            "dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " +
                            usershow
                          }
                        >
                          <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                            <div className="user-card">
                              <div className="user-avatar">
                                <span>
                                  {user?.first_name?.charAt(0) ?? ""}
                                  {user?.last_name?.charAt(0) ?? ""}
                                </span>
                              </div>
                              <div className="user-info">
                                <span className="lead-text">
                                  {user.first_name} {user.last_name}
                                </span>
                                <span className="sub-text">{user.email}</span>
                              </div>
                              <div className="user-action">
                                <a className="btn btn-icon mr-n2" href="#">
                                  <em className="icon ni ni-setting" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="dropdown-inner">
                            <ul className="link-list">
                              <li>
                                <a href="\viewprofile">
                                  <em className="icon ni ni-user-alt" />
                                  <span>View Profile</span>
                                </a>
                              </li>
                              <li>
                                <a href="/security">
                                  <em className="icon ni ni-setting-alt" />
                                  <span>Security Setting</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <em className="icon ni ni-activity-alt" />
                                  <span>Login Activity</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-inner">
                            <ul className="link-list">
                              <li>
                                <a href="\newlogin">
                                  <em className="icon ni ni-signout" />
                                  <span onClick={handleLogout}>Log Out</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="nk-content">
              <div className="container wide-xl">
                <div className="nk-content-inner">
                  <div
                    className={"nk-aside " + showMenu}
                    data-content="sideNav"
                    data-toggle-overlay="true"
                    data-toggle-screen="lg"
                    data-toggle-body="true"
                  >
                    <div className="nk-sidebar-menu" data-simplebar>
                      {/* Menu */}
                      <ul className="nk-menu">
                        <li className="nk-menu-heading">
                          <h6 className="overline-title">Menu</h6>
                        </li>

                        <li className="nk-menu-item active">
                          <a href="\testdash" className="nk-menu-link">
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-file-docs" />
                            </span>
                            <span className="nk-menu-text">Review Dashboard</span>
                          </a>
                          <ul className="nk-menu-sub">
                            <li className="nk-menu-item ">
                              <a href="\newcompleted" className="nk-menu-link">
                                <span className="nk-menu-text">
                                  Completed Students
                                </span>
                              </a>
                            </li>
                            <li className="nk-menu-item active">
                              <a href="\newcompleted" className="nk-menu-link">
                                <span className="nk-menu-text">Review</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nk-menu-item">
                          <a href="/newlogin" className="nk-menu-link">
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-signout" />
                            </span>
                            <span
                              onClick={handleLogout}
                              className="nk-menu-text"
                            >
                              Log Out
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* .nk-sidebar-menu */}
                    <div className="nk-aside-close">
                      <a href="#" className="toggle" data-target="sideNav">
                        <em className="icon ni ni-cross" />
                      </a>
                    </div>
                    {/* .nk-aside-close */}
                  </div>

                  <div className="nk-content-body">
                    <div className="nk-content-wrap">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-between-md g-4">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title">
                              Review Exam Dashboard
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-7">
                            <p className="exam-name mb-1">{`Result Id - ${result?.result ?? ""}`}</p>
                            <h6 className="text-primary ">
                              Student Name : {result?.student_name ?? ""}
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* .nk-block-head */}
                      <div className="nk-block">
                        <div className="card card-bordered">
                          <div
                            style={{
                              overflowX: "auto",
                              overflowY: "auto",
                              maxHeight: "800px",
                              maxWidth: "100%",
                            }}
                          >
                            <table className="table table-member">
                              <thead className="tb-member-head thead-light">
                                <tr className="tb-member-item">
                                  {[
                                    { label: "Sr No", w: "w-2" },
                                    { label: "Question", w: "w-50" },
                                    { label: "Correct Answer", w: "w-10" },
                                    { label: "Student Answer", w: "w-10" },
                                    { label: "Type", w: "w-10" },
                                    { label: "Given Marks", w: "w-10" },
                                    { label: "Total Marks", w: "w-10" },
                                  ].map((item, index) => (
                                    <th
                                      key={index}
                                      className={`tb-member-action text-center ${item.w}`}
                                    >
                                      <span className="overline-title">
                                        {item.label}
                                      </span>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="tb-member-body">
                                {data && data?.questions?.length > 0 ?
                                data?.questions?.map((item, i) => (
                                  <tr className="tb-member-item text-center">
                                    <td className="tb-member-role tb-col-md">
                                      <span>{i + 1}</span>
                                    </td>
                                    <td className="tb-member-role tb-col-md">
                                      <span>{item?.question ?? ""}</span>
                                    </td>
                                    <td className="tb-member-role tb-col-md">
                                      <span>{item?.correct_answers ?? ""}</span>
                                    </td>
                                    {/* <td className="tb-member-role tb-col-md">
                                      <span>{item?.user_response ?? ""}</span>
                                    </td> */}
                                    <td className="tb-member-role tb-col-md">
                                        {item?.user_response?.length > 10 ? (
                                          <span
                                          className="hoverEffect"
                                            title={item.user_response} 
                                            onClick={() => handelOpenModal(item)} 
                                          >
                                            {item.user_response.substring(0, 10)}...
                                          </span>
                                        ) : (
                                          <span>{item?.user_response ?? ""}</span>
                                        )}
                                    </td>
                                    <td className="tb-member-role tb-col-md">
                                      <span>{item?.type ?? ""}</span>
                                    </td>
                                    <td className="tb-member-role tb-col-md">
                                      <input
                                        // type="number"
                                        // className="form-control  p-2 py-2"
                                        className={`form-control p-2 py-2 ${
                                          inputErrors[i] ? "is-invalid" : ""
                                        }`}
                                        value={item?.given_marks ?? ""}
                                        // min="0"
                                        // max={item?.total_marks ?? 0}
                                        onChange={(e) =>
                                          handleMarksChange(i, e.target.value)
                                        }
                                      />
                                      {inputErrors[i] && (
                                          <div className="invalid-feedback">
                                            {inputErrors[i]}
                                          </div>
                                        )}
                                    </td>
                                    <td className="tb-member-role tb-col-md">
                                      <span>{item?.total_marks ?? ""}</span>
                                    </td>
                                  </tr>
                                ))
                                :
                                
                                <tr className="tb-member-item text-center" >
                                    <td
                                    colSpan="7"
                                    className="tb-member-role tb-col-md"
                                  >
                                   No Data Avialable for Selected Result Id
                                    </td>
                                </tr>
                                }

                                {/* Total Row  */}
                                {data && data?.questions?.length > 0 && <tr className="tb-member-item text-center">
                                  <td
                                    colSpan="5"
                                    className="tb-member-role tb-col-md"
                                  >
                                    <span>
                                      <strong>Total</strong>
                                    </span>
                                  </td>
                                  <td className="tb-member-role tb-col-md">
                                    <span>
                                      <strong>
                                        {data?.questions?.reduce(
                                          (total, item) =>
                                            total +
                                            (parseFloat(item?.given_marks) ||
                                              0),
                                          0
                                        )}
                                      </strong>
                                    </span>
                                  </td>
                                  <td className="tb-member-role tb-col-md">
                                    <span>
                                      <strong>
                                        {data?.questions?.reduce(
                                          (total, item) =>
                                            total +
                                            (parseFloat(item?.total_marks) ||
                                              0),
                                          0
                                        )}
                                      </strong>
                                    </span>
                                  </td>
                                </tr>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary mt-1"
                          onClick={handleUpdateMarks}
                          disabled={!data && data?.questions?.length === 0}
                        >
                          Update Marks
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubReviewModal open={openModal} onClose={()=>setOpenModal(false)} data={subJectiveData}/>
    </>
  );
};

export default Review;
