import React, { Component } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Http from "axios";
import Card from "react-bootstrap/Card";
import Dashboard from "./NewDashboard";
import NewLogin from "../Auth/NewLogin";
import { apiGet, logout } from "../Util/apiHelper";
import {
  getFromSessionStorage,
  saveToSessionStorage,
  SESSION_STORAGE_KEYS,
} from "../Util/sessionStorage";
import Header from "../components/header";

//toggleMenu is commented for user to show only examcarddashboard

class ExamCardDashboard extends Component {
  constructor() {
    super();
    this.state = {
      isLogged: false,
      isLoaded: false,
      exams: [],
      users: [],
      id: 0,
      found: false,
      showDate: false,
      userMenu: false,
      menu: false,
      user: null,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
  }
  getExamStatus = (exam) => {
    const examStartTime = Date.parse(
      "1970-01-01T" + exam.start_time.toString() + "Z"
    );
    const examEndTime = examStartTime + exam.duration * 60000;
    const now = Date.now();

    if (Date.parse(exam.date) + examStartTime < now) {
      if (examEndTime > now) {
        return "Start Exam";
      } else {
        return "Done";
      }
    } else {
      return "Not Started";
    }
  };
  gotoProfile = (id, exam) => {
    //localStorage.setItem('exam',JSON.stringify(this.state.exams[id-1]));
  };
  doesUserExist = (user, email) => {
    //matching the user and the invigilator
    if (email == undefined) {
      console.log("Email not defined");
      return false;
    }
    if (user.email == email && user.invig_id == this.state.user.id) {
      this.state.found = true;
      console.log("user found");
      this.state.email = email;
      this.state.user_id = user.id;
      return true;
    } else {
      //console.log("user does not exist")
      const errorMsgElement = document.querySelector("span#errorMsg");
      errorMsgElement.innerHTML =
        "User not attached to Invigilator. Please invite user";
      return false;
    }
  };
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ id: 0 });
    //TODO: return false if user doesn't exist and send an email. If exists send id to create a map entry
    //email to user_id conversion
    /*if(nam =='user_id')
    //this.state.users.filter(user=>((user.email==val)&&(user.invig_id==this.state.user.id))).map(user=>(this.setState({user_id:user.id})));
    this.state.users.filter(user=>(this.doesUserExist(user,val))).map(user=>(this.setState({user_id:user.id})));
    else*/
    this.setState({ [nam]: val });
    //if(!this.state.found)
    //	console.log("Failed to find user");
  };
  //Add Student
  handleClick = (exam) => {
    saveToSessionStorage(SESSION_STORAGE_KEYS.exam1, exam);
    // localStorage.setItem('exam1', JSON.stringify(exam));
    var status;
    const errorMsgElement = document.querySelector("span#errorMsg");

    if (exam.date !== undefined) {
      //Scheduled Exam
      //status=(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";
      status =
        Date.parse(exam.date) +
          Date.parse("1970-01-01T" + exam.start_time.toString() + "Z") <
        Date.now()
          ? Date.parse(exam.date) +
              Date.parse("1970-01-01T" + exam.start_time.toString() + "Z") +
              exam.duration * 60000 >
            Date.now()
            ? "Ongoing"
            : "Late"
          : "Not Started";
      console.log(status);
      if (status == "Ongoing") {
        //Ongoing Exam
        console.log("Too late to add a student");
      } else if (status == "Not Started") {
        //Upcoming Exam
        console.log("Adding student to exam");
        this.state.users
          .filter((user) => this.doesUserExist(user, this.state.user_id))
          .map((user) => this.setState({ user_id: user.id }));
        if (this.state.email) {
          var api =
            "exam-api/exame/" +
            this.state.user_id +
            "/" +
            exam.id +
            "/" +
            this.state.email +
            "/" +
            this.state.user.email;
          //TODO Add function to  invite user here if he does not exist
          Http.post(api)
            .then((res) => {
              console.log(res);
              errorMsgElement.innerHTML = this.state.email + " added";
            })
            .catch(function (error) {
              console.log(error);
              errorMsgElement.innerHTML = "Could not add " + this.state.email;
            });
        } else {
          console.log("Failed to find user");
          errorMsgElement.innerHTML =
            "Could not find " +
            this.state.user_id +
            ". Invite user from Student Dashboard";
        }
      } //Past Exam
      else {
        console.log("Exam has already occured.");
      }
    } //unscheduled exam
    else {
      this.state.users
        .filter((user) => this.doesUserExist(user, this.state.user_id))
        .map((user) => this.setState({ user_id: user.id }));
      if (this.state.email) {
        var api =
          "exam-api/exame/" +
          this.state.user_id +
          "/" +
          exam.id +
          "/" +
          this.state.email +
          "/" +
          this.state.user.email;
        //TODO Add function to  invite user here
        Http.post(api)
          .then((res) => {
            console.log(res);
            errorMsgElement.innerHTML = "Added " + this.state.email;
          })
          .catch(function (error) {
            console.log(error);
            errorMsgElement.innerHTML = "Could not add " + this.state.email;
          });
      } else {
        console.log("Failed to find user");
      }
    }
  };

  startExam = (exam) => {
    const errorMsgElement = document.querySelector("span#errorMsg");
    //update start time and end time
    //save exam to the local storage
    //var x=new Date();
    //exam.date=x.getFullYear()+"-"+(x.getMonth()+1)+"-"+x.getDate();
    //exam.start_time =x.getHours()+":"+x.getMinutes();
    if (exam.status != "Completed" || exam.status == null) {
      var x = new Date().toISOString();
      exam.date = x.slice(0, 10);
      exam.start_time = x.slice(11, 19);
      exam.status = "Ongoing";
      saveToSessionStorage(SESSION_STORAGE_KEYS.exam1, exam);
      // localStorage.setItem('exam1', JSON.stringify(exam));
      // localStorage.setItem('user', JSON.stringify(this.state.user));
      //this.getAssertion();
      if (this.state.user.role != "invig") {
        //Student schedules Exam
        Http.put(
          "exam-api/examt/" +
            this.state.user.id +
            "/" +
            exam.id +
            "/" +
            exam.date +
            "/" +
            exam.start_time,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              // Add any other headers if required for your API, such as authorization headers
            },
          }
        )
          .then((res) => {
            console.log("Exam Started");
            errorMsgElement.classList.remove("badge-outline-info");
            errorMsgElement.classList.add("badge-outline-success");
            errorMsgElement.innerHTML = "Exam Started";
            for (let i = 0; i < this.state.exams.length; i++) {
              const updatedExams = [...this.state.exams];
              if (updatedExams[i].id == exam.id) {
                updatedExams[i].date = exam.date;
                updatedExams[i].start_time = exam.start_time;
                this.setState({ exams: updatedExams });
                break;
              }
            }
            return exam;
          })
          .catch(function (error) {
            errorMsgElement.classList.remove("badge-outline-success");
            errorMsgElement.classList.add("badge-outline-warning");
            errorMsgElement.innerHTML = error;
            console.log(error);
          });
      }
      this.state.link = "/verifyhelp";
    } else if (exam.status == "Ongoing") {
      var x = new Date().toISOString();
      exam.date = x.slice(0, 10);
      exam.start_time = x.slice(11, 19);
      exam.status = "Ongoing";
      this.getAssertion();
      exam.duration = exam.duration - exam.attendance;
      if (exam.duration > 0) {
        saveToSessionStorage(SESSION_STORAGE_KEYS.exam1, exam);
        // localStorage.setItem('exam1', JSON.stringify(exam));
        // localStorage.setItem('user', JSON.stringify(this.state.user));
        this.state.link = "/verifyhelp";
      } else {
        //submit the exam after time over
        this.state.link = "#";
        exam.status = "Completed";
        var api =
          "exam-api/exams/" + this.state.user.id + "/" + this.state.exam.id;
        Http.put(api).then((response) => {
          alert("Time Over");
        });
      }
    } else {
      this.state.link = "#";
      alert("You have already submitted your exam");
    }
    return exam;
  };
  viewExam = () => {
    console.log("View Exam");
  };

  //schedule an exam for unscheduled exams
  scheduleExam = (exam) => {
    const errorMsgElement = document.querySelector("span#errorMsg");
    saveToSessionStorage(SESSION_STORAGE_KEYS.exam1, exam);
    // localStorage.setItem('exam1', JSON.stringify(exam));

    if (exam.date === undefined) {
      console.log("Exam Schedule routine clicked");
      this.setState({ showDate: true });
      var x = new Date(
        Date.parse(this.state.date) +
          Date.parse("1970-01-01T" + this.state.start_time + "Z")
      );
      var x = new Date(
        Date.parse(this.state.date + "T" + this.state.start_time + "Z")
      );
      var y = new Date(x.getTime() + x.getTimezoneOffset() * 60000);
      this.state.date = y.toISOString();
      let time = this.state.date.split("T");
      this.state.date = time[0];
      time = time[1].split(".");
      this.state.start_time = time[0];
      exam.date = this.state.date;
      exam.start_time = this.state.start_time;
      for (let i = 0; i < this.state.exams.length; i++) {
        const updatedExams = [...this.state.exams];
        if (updatedExams[i].id == exam.id) {
          updatedExams[i].date = exam.date;
          updatedExams[i].start_time = exam.start_time;
          this.setState({ exams: updatedExams });
          break;
        }
      }

      if (this.state.user.role != "invig") {
        //Student schedules Exam
        Http.put(
          "exam-api/examt/" +
            this.state.user.id +
            "/" +
            exam.id +
            "/" +
            this.state.date +
            "/" +
            this.state.start_time,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              // Add any other headers if required for your API, such as authorization headers
            },
          }
        )
          .then((res) => {
            console.log("Exam Scheduled");
            errorMsgElement.classList.remove("badge-outline-info");
            errorMsgElement.classList.add("badge-outline-success");
            errorMsgElement.innerHTML = "Exam Scheduled";
            return exam;
          })
          .catch(function (error) {
            errorMsgElement.innerHTML = error;
            console.log(error);
          });
      } else {
        //Proctor Schedules Exam
        Http.put(
          "exam-api/exam/" + exam.id,
          {
            date: this.state.date,
            start_time: this.state.start_time,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            console.log("Exam scheduled Successfully");
            errorMsgElement.classList.remove("badge-outline-info");
            errorMsgElement.classList.add("badge-outline-success");
            errorMsgElement.innerHTML = "Exam scheduled successfully";
          })
          .catch(function (error) {
            errorMsgElement.innerHTML = error;
            console.log(error);
          });
      }
    } else {
      console.log("Exam already scheduled at ", exam.date, exam.start_time);
      errorMsgElement.innerHTML =
        "Exam already scheduled at " + exam.date + " " + exam.start_time;
    }
  };

  fetchDataAsPerUser = async (user) => {
    //for invigilators and
    //TODO: admin
    const { id: userID = "", token: userToken = "" } = user || {};
    const api =
      user?.role === "invig" ? "/exam-api/exam" : `/exam-api/examt/${userID}`;
    // console.log(api,'APIFOR GET CALL ISJFAJLDFJAJSDFJ AJDKFJAKLSDJ F')
    const response = await apiGet(api);
    // console.log(response,'final response jaklsd')
    const exams = response || [];
    const finalUpdatedExam = [];

    if (exams && exams?.length) {
      Http.defaults.headers.common["Authorization"] = userToken;

      for (let i = 0; i < exams.length; i++) {
        const singleExam = exams[i] || {};
        //if its an unscheduled exam
        if (singleExam.date || singleExam.exam_date) {
          const resp = await apiGet(`/user-api/userua/${singleExam.id}`);
          if (resp && resp) {
            for (let j = 0; j < resp.length; j++) {
              if (user.id == resp[j].id) {
                // const updatedExams = JSON.parse(JSON.stringify(exams))
                //updatedExams[i].status = resp.data[j].answer;
                if (resp[j].attendance > 0) {
                  singleExam.status = "Ongoing";
                }
                if (resp[j].answer != "") {
                  singleExam.status = "Completed";
                }

                finalUpdatedExam.push(singleExam);
                // this.setState({ exams: updatedExams });
              }
              // this.getExamStatus(this.state.exams[i].id,this.state.user.id);
            }
          }
        } else {
          //student scheduled exam
          // const updatedExams = [...this.state.exams];
          // updatedExams[i].date = updatedExams[i].exam_date;
          // updatedExams[i].start_time = updatedExams[i].exam_time;
          // this.setState({ exams: updatedExams });
          finalUpdatedExam.push({
            ...singleExam,
            date: singleExam?.exam_date,
            start_time: singleExam?.exam_time,
          });
        }
      }
      console.log("fina", finalUpdatedExam);
      const response = await apiGet(`/user-api/userd/${user?.id}`);

      // console.log(user,'USERIS ---------')
      this.setState({
        ...this.state,
        isLoaded: true,
        isLogged: true,
        exams: finalUpdatedExam,
        user: user,
        users: response,
      });
    }
  };

  initiaDataSetup = async () => {
    let tout, minutes_left;
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user);
    console.log(user, "auser jkalsdf aksjdlfa sd");
    if (user) {
      await this.fetchDataAsPerUser(user);
    } else {
      console.log("no user is therer -------");
    }
  };

  // async componentWillMount() {
  //   // const user = JSON.parse(localStorage.getItem('user'));
  //   const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
  //   var tout, minutes_left;
  //   if (this.state.user = user) {
  //     this.setState({
  //       ...this.state,
  //       isLogged: true
  //     })
  //     // this.state.isLogged = true;
  //   }

  //   else {
  //     console.log("User not Logged in");
  //     if (this.props.user) {
  //       this.state.user = this.props.user;
  //       this.state.isLogged = true;
  //     }
  //     else
  //       this.state.isLogged = false;
  //   }
  //   //for invigilators and
  //   //TODO: admin
  //   if (this.state.user.role == 'invig') {
  //     var api = "exam-api/exam";
  //   }
  //   else {
  //     var api = "exam-api/examt/" + this.state.user.id;
  //     if (this.state.user)
  //       localStorage.setItem('user', JSON.stringify(this.state.user));
  //   }
  //   const response = await Http.get(api);
  //   /*	.then(res => {
  //                   const response = res.data;
  //                   this.setState({ isLoaded:true, exams:response.data });
  //       //errorMsgElement.innerHTML="Exam Scheduled Successfully";
  //             //return <Redirect to="/examdash" />
  //             })
  //             .catch(function (error) {
  //               //this.setState({ status:true});
  //               console.log(error);
  //               this.setState({ isLoaded:false});
  //   //errorMsgElement.innerHTML=error;
  //             });*/
  //   this.setState({ isLoaded: true, exams: response.data });
  //   for (var i = 0; i < this.state.exams.length; i++) {
  //     //if its an unscheduled exam
  //     Http.defaults.headers.common['Authorization'] = this.state.user.token;
  //     if ((this.state.exams[i].date !== undefined) || (this.state.exams[i].exam_date !== undefined)) {
  //       var api = 'user-api/userua/' + this.state.exams[i].id;
  //       const resp = await Http.get(api);
  //       for (var j = 0; j < resp.data.length; j++) {
  //         if (this.state.user.id == resp.data[j].id) {
  //           const updatedExams = [...this.state.exams];
  //           //updatedExams[i].status = resp.data[j].answer;
  //           if ((resp.data[j].attendance > 0))
  //             updatedExams[i].status = "Ongoing";
  //           if (resp.data[j].answer != "")
  //             updatedExams[i].status = "Completed";
  //           this.setState({ exams: updatedExams });
  //         }
  //         // this.getExamStatus(this.state.exams[i].id,this.state.user.id);
  //       }
  //       // this.state.exams[i].status=this.getExamStatus(this.state.exams[i].id,this.state.user.id);
  //     }
  //     else { //student scheduled exam
  //       const updatedExams = [...this.state.exams];
  //       updatedExams[i].date = updatedExams[i].exam_date;
  //       updatedExams[i].start_time = updatedExams[i].exam_time;
  //       this.setState({ exams: updatedExams });
  //     }
  //   }
  //   this.state.link = "/verifyhelp";
  // }

  componentDidMount() {
    this.initiaDataSetup();
  }

  // componentDidMount() {
  // const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
  // const response = await apiGet(`/user-api/userd/${user?.id}`);
  // console.log(user,'userjisjfka ')

  // this.setState({ ...this.state,isLogged:true, user:user ,users:response.data });

  //  this.initiaDataSetup()
  // var api;
  // api = 'user-api/userd/' + this.state.user.id;
  // const response = await Http.get(api);
  // this.setState({ isLoaded: true, users: response.data });
  //console.log(this.state.users);
  // }

  getAssertion = () => {
    this.setState({ security_button: "Wait for prompt" });
    Http.get("/user/" + this.state.email + "/exists", {}, null, "json")
      .then((response) => {
        console.log(response);
      })
      .then(() => {
        //var user_verification = $('#select-verification').find(':selected').val();
        //            //var txAuthSimple_extension = $('#extension-input').val();
        //
        Http.get(
          "/assertion/" +
            this.state.email +
            "?userVer=discouraged&txAuthExtension=",
          {
            userVer: "discouraged",
            txAuthExtension: "",
          },
          null,
          "json"
        ).then((makeAssertionOptions) => {
          console.log("Assertion Options:");
          console.log(makeAssertionOptions);
          makeAssertionOptions.data.publicKey.challenge = this.bufferDecode(
            makeAssertionOptions.data.publicKey.challenge
          );
          makeAssertionOptions.data.publicKey.allowCredentials.forEach(
            (listItem) => {
              listItem.id = this.bufferDecode(listItem.id);
            }
          );
          console.log(makeAssertionOptions.data);
          navigator.credentials
            .get({
              publicKey: makeAssertionOptions.data.publicKey,
            })
            .then((credential) => {
              console.log(credential);
              this.verifyAssertion(credential);
            })
            .catch((err) => {
              console.log(err.name);
            });
        });
      })
      .catch((error) => {
        if (!error.exists) {
          //alert("User not found, try registering one first!");
        }
        return;
      });
  };

  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    //  this.setState({ menu: !this.state.menu });
  }
  toLocal = (utctime) => {
    var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z"));
    x += this.state.user.timezone * 60000;
    return x.toLocaleString().slice(16, 24);
  };

  async getExamStatus(exam_id, user_id) {
    var api = "user-api/userua/" + exam_id;
    const response = await Http.get(api);
    for (var i = 0; i < response.data.length; i++) {
      if (user_id == response.data[i].id) return response.data[i].answer;
    }
    //console.log(response.data.answer);
    //alert(response.data.answer);
    //return response.data.answer;
  }

  // async handleLogout(event) {
  //   console.log("loggon out");
  //   try {
  //     await logout();
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     this.props.history.replace({
  //       pathname: "/newlogin",
  //       state: {
  //         from: "",
  //       },
  //     });
  //   } catch (err) {
  //     alert("Error in loggin out , please try again.");
  //   }
  // }

  handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    this.props.history.replace({
      pathname: "/newlogin",
      state: {
        from: "",
      },
    });
  };
  render() {
    if (this.state.user) {
      const show = this.state.menu ? "active" : "noactive";
      const showMenu = this.state.menu ? "content-active" : "noactive";
      const usershow = this.state.userMenu ? "show" : "";
      const { users } = this.state;
      let usersList =
        users.length > 0 &&
        users
          .filter(
            (user) => user.email != null && user.invig_id == this.state.user.id
          )
          .map((user, i) => {
            return (
              <option key={i} value={user.email}>
                {user.first_name + "(" + user.email + ")"}
              </option>
            );
          }, this);

      return (
        <div>
          <Header
            user={
              this.state.user ||
              getFromSessionStorage(SESSION_STORAGE_KEYS.user)
            }
            isToggleMenu={false}
          />
          {/* content @s */}
          <div className="nk-content mt-5">
            <div className="container wide-xl">
              <div className="nk-content-inner">
                <div
                  className={"nk-aside " + showMenu}
                  data-content="sideNav"
                  data-toggle-overlay="true"
                  data-toggle-screen="lg"
                  data-toggle-body="true"
                >
                  <div className="nk-sidebar-menu" data-simplebar>
                    {/* Menu */}
                    <ul className="nk-menu">
                      <li className="nk-menu-heading d-none">
                        <h6 className="overline-title">Menu</h6>
                      </li>
                      <li
                        style={{
                          display:
                            this.state.user.role == "invig" ? "block" : "none",
                        }}
                        className="nk-menu-item"
                      >
                        <a href="\newdashboard" className="nk-menu-link">
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-users" />
                          </span>
                          <span className="nk-menu-text">
                            Student Dashboard
                          </span>
                        </a>
                      </li>
                      <li className="nk-menu-item active">
                        <a href="\testdash" className="nk-menu-link">
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-file-docs" />
                          </span>
                          <span className="nk-menu-text">Exam Dashboard</span>
                        </a>
                        <ul className="nk-menu-sub">
                          <li className="nk-menu-item">
                            <a href="\testdash" className="nk-menu-link">
                              <span className="nk-menu-text">
                                Ongoing Exams
                              </span>
                            </a>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\upcoming" className="nk-menu-link">
                              <span className="nk-menu-text">
                                Upcoming Exams
                              </span>
                            </a>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\unscheduled" className="nk-menu-link">
                              <span className="nk-menu-text">
                                Unscheduled Exams
                              </span>
                            </a>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\pastexamdash" className="nk-menu-link">
                              <span className="nk-menu-text">Past Exams </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="nk-menu-item">
                        <a href="\newlogin" className="nk-menu-link">
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-signout" />
                          </span>
                          <span
                            onClick={this.handleLogout}
                            className="nk-menu-text"
                          >
                            Log Out
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* .nk-sidebar-menu */}
                  <div className="nk-aside-close">
                    <a href="#" className="toggle" data-target="sideNav">
                      <em className="icon ni ni-cross" />
                    </a>
                  </div>
                  {/* .nk-aside-close */}
                </div>
                {/* .nk-aside */}
                <div className="nk-content-body">
                  <div className="nk-content-wrap">
                    <div className="">
                      <div className="nk-block-between-md g-4">
                        <div className="nk-block-head-content">
                          <h2 className="nk-block-title">Exam Dashboard</h2>
                          <h5 className="nk-block-title fw-normal">
                            All Exams
                          </h5>
                          <div
                            className={
                              "nk-block-des " +
                              (this.state.user.role == "invig"
                                ? ""
                                : "noactive")
                            }
                          >
                            <p>
                              You can add exams. For removing please mail
                              care@monitorexam.com
                            </p>
                          </div>
                          <div
                            className={
                              "nk-block-des " +
                              (this.state.user.role == "invig"
                                ? "noactive"
                                : "")
                            }
                          ></div>
                        </div>
                        <div
                          className={
                            "nk-block-head-content " +
                            (this.state.user.role == "invig" ? "" : "noactive")
                          }
                        >
                          <ul className="nk-block-tools gx-3">
                            <li>
                              <a href="/newform" className="btn btn-primary">
                                +Exam
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .nk-block-head */}
                    <div className="nk-block">
                      <p>
                        <span id="errorMsg"></span>
                      </p>
                      <div className="row">
                        <>
                          {/*this.state.exams.filter(exam => ((exam.date === undefined) && (exam.exam_date == undefined) && ((exam.invig_id == this.state.user.id) || (exam.invig_id == this.state.user.invig_id)))).map(exam => ( */}
                          {this.state.exams
                            .filter(
                              (exam) =>
                                exam.invig_id == this.state.user.invig_id
                            )
                            .reverse()
                            .map((exam) => (
                              <div key={exam?.id} className="col-sm-4">
                                <div className="card card-bordered exam-card">
                                  <a href="#">
                                    <img
                                      src="images/math-exam.jpeg"
                                      className="exam-card-img"
                                      alt="profile-pic"
                                    ></img>
                                  </a>
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      {exam.name}{" "}
                                      <span className="badge badge-dim badge-success">
                                        free
                                      </span>
                                    </h5>
                                    <p className="card-text">
                                      <span className="left">
                                        <em className="icon ni ni-calendar"></em>{" "}
                                        {exam.window_start_date !== undefined
                                          ? new Date(
                                              Date.parse(
                                                exam.window_start_date +
                                                  "T" +
                                                  exam.window_start_time +
                                                  "Z"
                                              )
                                            ).toLocaleString()
                                          : "Anytime"}{" "}
                                        {exam.window_start_time !== undefined
                                          ? exam.windows_start_time
                                          : ""}{" "}
                                        <em className="icon ni ni-arrow-long-right"></em>
                                      </span>{" "}
                                      <span className="right">
                                        <em className="icon ni ni-calendar"></em>{" "}
                                        {exam.window_start_date != undefined &&
                                        exam.window_start_time !== undefined
                                          ? new Date(
                                              Date.parse(
                                                exam.window_start_date
                                              ) +
                                                Date.parse(
                                                  "1970-01-01T" +
                                                    exam.window_start_time.toString() +
                                                    "Z"
                                                ) +
                                                (Math.floor(exam.duration) +
                                                  Math.floor(
                                                    exam.window_duration
                                                  )) *
                                                  60000
                                            ).toLocaleString()
                                          : "Anytime"}
                                      </span>
                                    </p>

                                    <p className="card-text">
                                      <span className="left">
                                        <em className="icon ni ni-clock"></em>{" "}
                                        {exam.duration + " minutes"}
                                      </span>{" "}
                                      <span className="right d-none">
                                        <em className="icon ni ni-question"></em>{" "}
                                        25 Questions
                                      </span>
                                    </p>
                                    <p className="card-text d-none">
                                      <em className="icon ni ni-users"></em> 200
                                      enrolled
                                    </p>
                                    <a
                                      href={
                                        exam.status == "Completed" ||
                                        exam.status == "Not Started" ||
                                        exam.status == "Late"
                                          ? "#"
                                          : "/verifyhelp"
                                      }
                                      className="btn btn-primary"
                                      style={{
                                        color: "white",
                                        display:
                                          this.state.user.role == "invig"
                                            ? "none"
                                            : "block",
                                      }}
                                      onClick={() => this.startExam(exam)}
                                    >
                                      {/*exam.status = ((Date.parse(exam.window_start_date) + Date.parse("1970-01-01T" + exam.window_start_time.toString() + "Z")) < (Date.now())) ? (((Date.parse(exam.window_start_date) + Date.parse("1970-01-01T" + exam.window_start_time.toString() + "Z") + exam.window_duration * 60000) > (Date.now())) ? "Start Exam" : "Late") : "Not Started"*/}
                                      {"Start Exam"}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      </div>
                    </div>
                  </div>
                  {/* footer @e */}
                </div>
              </div>
            </div>
          </div>
          {/* content @e */}
          {/* wrap @e */}
          {/* main @e */}
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
    } else {
      return null;
    }
  }
}
export default ExamCardDashboard;
