import React, {
  Component
} from 'react'
import Http from 'axios'
import MinChat from '../Chat/MinChat'
import NewLogin from '../Auth/NewLogin'
import NewOngoingExamDashboard from '../Dashboard/NewOngoingExamDashboard'
import Button from 'react-bootstrap/Button'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Base64js from 'base64-js'
import CountdownTimer from '../Util/CountdownTimer';
import { getFromSessionStorage, saveToSessionStorage, SESSION_STORAGE_KEYS } from '../Util/sessionStorage'
import { apiPut } from '../Util/apiHelper'
import { getAssertion, isMirroringOn, isSubmitted, toggleFullScreen, toggleFullScreenOffline, verifyAssertion } from '../Util/commonFuns'
import { ALERT_MSG_TIME_LIMIT_EXCEED, SUCCESS_MSG_EXAM_COMPLETE } from '../Util/alertMessages'
import Header from '../components/header'

class NewTest extends React.Component {
  constructor() {
    super();
    this.state = {
      timerID: 0,
      timerCap: 0,
      timerFIDO: 0,
      status: "Initial",
      testStarted: false,
      camera_state: false,
      network_state: false,
      battery_state: false,
      mirroring_state: false,
      battery_level: 5,
      cred: 0,
      ip: "0.0.0.0",
      captured: false,
      displayToast: true,
      userMenu: false,
      exam: {},
      user: {},
      menu: false,
      attendence: 0,
      minutes_left: 0,
      live_pic: "",
      exam_status: "",
      last_login: "",
   };
   this.iframeRef = React.createRef();
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.getExam = this.getExam.bind(this);
    this.captureDesktop = this.captureDesktop.bind(this);
    this._isMirroringOn = this._isMirroringOn.bind(this);
  }
  sendTokenToIframe = (token) => {
    const target = process.env.NODE_ENV !== "development" 
      ? 'https://assessme.monitorexam.com' 
      : 'http://127.0.0.1:8000' ;
    if (this.iframeRef.current) {
      this.iframeRef.current.contentWindow.postMessage(
        { type: 'SET_TOKEN', token:token}, 
        target  
      );
    }
  };
  handleSubmit = (event) => {
    //event.preventDefault();
    const ansMsgElement = document.getElementById("ansMsg")
    const ansMsgNotElement = document.getElementById("ansMsgNot")

    const errorMsgElement = document.getElementById("errorMsg");
    errorMsgElement.innerHTML = "Exam Submitted Successfully";
    this.setState({ status: "Completed" });
    //  this.updateState();
    ansMsgElement.innerHTML = "Exam Submitted Successfully";
    ansMsgNotElement.innerHTML = "Exam Submitted";
    ReactDOM.render(<NewOngoingExamDashboard />, document.getElementById("root"));
  }

  showPosition = (position) => {
    this.state.lat = position.coords.latitude;
    this.state.lng = position.coords.longitude;
  }
  showMessage = () => {
    alert("This operation is not allowed");
  }
  keydownHandler(e) {
    if (e.ctrlKey) {
      alert("You are not permitted to press Ctrl Key");

      if ((e.keyCode === 86 || e.keyCode === 118) && e.ctrlKey) this.showMessage();
    }
  }

  _isSubmitted = async () => {
    try {
      const res = await isSubmitted(this.state.exam)
      if (res) {
        const _users = res?.data.filter(user => ((user.answer == "completed") && (user.id == this.state.user.id)))
          .map(user => this.handleSubmit(), this);
        this.setState({ ...this.state.users, users: _users });
      }
    } catch (err) {
        console.log(err,'ERROR IN SUBMIT ')
    }

  }

  examCompleted = async (timerMsgElement = null, errorMsgElement = null, status, cred) => {
    // Stop all intervals
    clearInterval(this.timerID);
    clearInterval(this.timerCap);
    clearInterval(this.timerFIDO);

    console.log("Exam Over. Stopped sending status");

    if (timerMsgElement) {
        timerMsgElement.innerHTML = 0;
    }

    // Update exam status if the time is up or the status is "Completed"
    if (this.state.minutes_left <= 0 || this.state.status === "Completed") {
        this.setState({
            exam_status: "Done"
        });
    }

    try {
        // Construct API URL
        const api = `/exam-api/examc/${this.state.user.id}/${this.state.exam.id}/${this.state.user.cred}`;
        const res = await apiPut(api);

        if (res) {
            console.log("Exam status response:", res);

            if (this.state.status === "Completed") {
                // Update session storage and state for user status and cred
                saveToSessionStorage(SESSION_STORAGE_KEYS.user, {
                    ...this.state.user,
                    status: "free",
                    cred: 0
                });

                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        status: "free",
                        cred: 0
                    }
                }));

                // Send update to user API
                await apiPut(`/user-api/user/${this.state.user.id}`, {
                    status: status,
                    cred: cred
                });

                if (errorMsgElement) {
                    errorMsgElement.innerHTML = "You can logout now";
                }
                console.log("User updated successfully");
                alert(SUCCESS_MSG_EXAM_COMPLETE);
            } else {
                if (errorMsgElement) {
                    errorMsgElement.innerHTML = ALERT_MSG_TIME_LIMIT_EXCEED;
                }
                alert(ALERT_MSG_TIME_LIMIT_EXCEED);
            }
        }
    } catch (error) {
        console.error("Error completing exam:", error);
        if (errorMsgElement) {
            errorMsgElement.innerHTML = "An error occurred while completing the exam.";
        }
    }
};


markAttendenceAtBeginning = async (errorMsgElement=null) => {
  const userToken = getFromSessionStorage(SESSION_STORAGE_KEYS.accessToken);
  console.log("userToken", userToken);

  if (!userToken) {
    errorMsgElement.innerHTML = "User token not found";
    console.log('User token not found');
    return;
  }

  const api = `/exam-api/examp/${this.state.user.id}/${this.state.exam.id}`;

  try {
    const apiRes = await apiPut(api);
    console.log('apiRes', apiRes);
    errorMsgElement.innerHTML = "Attendance marked successfully";
    console.log("Attendance marked successfully");
    this.state.attendence = 1;
  
  } catch (error) {
    errorMsgElement.innerHTML = "Error marking attendance";
    console.log('Error marking attendance:', error); 
  }
}


  // markAttendenceAtBeginning = (errorMsgElement=null)=>{
  //   return new Promise((resolve,reject)=>{
  //           const userToken = getFromSessionStorage(SESSION_STORAGE_KEYS.accessToken);
  //   console.log("userToken by vivek", userToken);

  //   if (!userToken) {
  //     errorMsgElement.innerHTML = "User token not found";
  //     reject(new Error("User token not found"));
  //     return;
  //   }

  //   const api = `exam-api/examp/${this.state.user.id}/${this.state.exam.id}`;
    
  //   // Check if Http is axios and the token is in the correct format
  //   Http.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;

  //     Http.put(api)
  //       .then(res => {
  //         console.log("examp p vivek",res);
  //         errorMsgElement.innerHTML = "Attendance marked successfully";
  //         console.log("Attendance marked successfully");
  //         this.setState({ ...this.state, attendence: 1 });
  //          resolve(this.state.user?.cred  ? this.state.user?.cred :  0)
  //       })
  //       .catch(function (error) {
  //         errorMsgElement.innerHTML = "Error marking attendance";
  //         console.log(error);
  //         reject(error)
  //       });
  //   })

  // }

  // OVER-ALL CHECK FOR STUDENT UPDATE , EXAMINER FUNCTIONALITY  FOR EVERY 30SEC
  updateState = async (event) => {
  let status, cred = 0;

    //cred is a credibility score
    const errorMsgElement = document.querySelector('span#errorMsg');
    const timerMsgElement = document.querySelector('span#timer');
    const istatus = document.querySelector('input#status');
    const deskfilename = document.querySelector('input#deskfilename'); // view(screenshot)
    const mstatus = document.querySelector('input#mstatus');
    const notification = document.getElementById("ansMsgNot"); // more active

    // this.isSubmitted(); // is sumbmitted or not exam
   await this._isSubmitted(); // is sumbmitted or not exam

   // notification.innerHTML = " <div className=\"nk-notification-content\"> <div className=\"nk-notification-text\" id=\"ansMsgNot\"> Exam ongoing </div> </div>";

    // this.isMirroringOn(); // FOR MIRRORING --------
    await this._isMirroringOn(); // FOR MIRRORING --------

    if (this.state.status == "Completed" || this.state.exam_status == "Done" || this.state.minutes_left <= 0) {
      this.examCompleted(timerMsgElement,errorMsgElement,status,cred)
    }

    //Http.defaults.headers.common['Authorization'] = this.state.user.token;
    console.log(document.hidden || document.msHidden || document.webkitHidden);
    console.log(document.webkitVisibilityState);
    //Mark attendance at the beginning

    if ((this.state.cred == 0) && (!this.state.attendence)) {
      const res = await this.markAttendenceAtBeginning(errorMsgElement)
      if(res) { cred = res }
    }
    else {
      cred = this.state.cred;
    }
    this.setState({ ...this.state, status: istatus.value });
    if (mstatus.value == "true")
      this.setState({ ...this.state, mirroring_state: true });
    else
      this.setState({ ...this.state, mirroring_state: false });
    console.log(this.state.status);
    if (document.hidden || document.webkitVisibilityState != "visible" && istatus.value == "Present") {
      status = "Distracted";
      alert("Warning: Please pay attention to your exam");
      cred = cred - 1;
      if (cred < -20 && this.state.user.desk_pic == null) {
        this.captureDesktop();
        errorMsgElement.innerHTML = `Invigilator has requested a desktop screenshot. Please allow`;
        alert("Invigilator has requested a desktop screenshot. Please allow");
        this.state.desk_pic = deskfilename.value;
      }
    }
    else if (this.state.status == "Away") {
      status = "Away";
      cred = cred - 1;
    }
    else if (this.state.status == "Doubt") {
      status = "Doubt";
      cred = cred - 2;
    }
    else if (["Looking up", "Looking down", "Looking side"].includes(this.state.status)) {
      status = this.state.status;
      cred = cred - 1;
    }
    else if (this.state.status == "Completed") {
      status = "Completed";
    } else {
      status = "Present";
      cred = cred + 4;
    }
    this.setState({ ...this.state, cred: cred });
    this.environmentCheck(); // 

    if (this.state.exam_status == "Done") {
      clearInterval(this.timerID);
      clearInterval(this.timerCap);
      status = "Completed";
    }

    // this.state.user.status = status;
    // this.state.user.cred = cred;
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        network_state: this.state.network_state,
        mirroring_state: this.state.mirroring_state,
        battery_state: this.state.battery_state,
        battery_level: this.state.battery_level,
        camera_state: this.state.camera_state,
        live_pic: this.state.live_pic,
        desk_pic: this.state.desk_pic,
        status: status,
        cred: cred
      }
    });
    // this.state.user.last_login = last_login;
    // this.state.user.network_state = this.state.network_state;
    // this.state.user.mirroring_state = this.state.mirroring_state;
    // this.state.user.battery_state = this.state.battery_state;
    // this.state.user.battery_level = this.state.battery_level;
    // this.state.user.camera_state = this.state.camera_state;
    // this.state.user.live_pic = this.state.live_pic;
    // this.state.user.desk_pic = this.state.desk_pic;
    // localStorage.setItem('user', JSON.stringify(this.state.user));

    const last_login = new Date().toISOString().slice(0, 19).replace('T', ' ');
    //separate handling for user having a live pic and not
    if (this.state.user.live_pic || this.state.live_pic) {
        console.log("hitting api for user update")
    // print axios headers
    console.log(Http.defaults.headers);
      Http.put('user-api/user/' + this.state.user.id, {
        "camera_state": this.state.camera_state,
        "network_state": this.state.network_state,
        "battery_state": this.state.battery_state,
        "battery_level": this.state.battery_level.toString(),
        "status": status,
        "ip": this.state.user.ip,
        "lat": this.state.lat,
        "lng": this.state.lng,
        "last_login": last_login,
        "live_pic": this.state.live_pic,
        "cred": cred,
        "desk_pic": this.state.desk_pic
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {

          //console.log("User updated Successfully");
          if (!this.state.attendence) {
            var api = "exam-api/examp/" + this.state.user.id + "/" + this.state.exam.id;
            Http.put(api)
              .then(res => {
                console.log("vivek res",res);
                this.setState({ attendence: 1 });
              })
              .catch(function (error) {
                errorMsgElement.innerHTML = "Error sending your status. Please contact your proctor on chat";
                console.log(error);
              });
          }
        })
    } else { //This might be dead code
    console.log("hitting api for user update")
    // print axios headers
    console.log(Http.defaults.headers);
      Http.put('user-api/user/' + this.state.user.id, {
        "camera_state": this.state.camera_state,
        "network_state": this.state.network_state,
        "battery_state": this.state.battery_state,
        "battery_level": this.state.battery_level.toString(),
        "status": status,
        "ip": this.state.user.ip,
        "lat": this.state.lat,
        "lng": this.state.lng,
        "last_login": last_login,
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          if (!this.state.attendence) {
            var api = "exam-api/examp/" + this.state.user.id + "/" + this.state.exam.id;
            Http.put(api)
              .then(res => {
                console.log(res);
                this.setState({ attendence: 1 });
              })
              .catch(function (error) {
                errorMsgElement.innerHTML = "Unable to mark attendance. Please contact your proctor on chat";
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          //errorMsgElement.innerHTML = "Token Timer expired. Please login again";
          //alert("Token Timer expired. Please login again");
          //sessionStorage.clear();
          console.log(error);
        });
    }
  }
  //standard function. Assign value to name
  handleInput = (event) => {
    event.preventDefault();
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({
      [nam]: val
    });
    if (nam == "exam_id")
      console.log(this.state.exam_id)
  }
  //Clear the timerID set in ComponentDidUpdate 
  //test whether this works or not
  componentWillUnmount() {
    clearInterval(this.timerID);
    clearInterval(this.timerCap);
    document.removeEventListener('keydown', this.keydownHandler);
  }
  async startPiP(event) {
    console.log('Toggling Picture-in-Picture...');
    const video = document.getElementById('video');
    // togglePipButton.disabled = true;
    try {
      if (video !== document.pictureInPictureElement)
        await video.requestPictureInPicture();
      else
        await document.exitPictureInPicture();
    } catch (error) {
      console.log(`> Argh! ${error}`);
    } finally {
      //togglePipButton.disabled = false;
    }
  }
  async captureDesktop(event) {
    const deskvideo = document.getElementById('deskvideo');
    const deskcanvas = document.getElementById('deskcanvas');
    const errorMsgElement = document.querySelector('span#errorMsg');
    const deskfname = document.querySelector('input#deskfname');
    const deskfilename = document.querySelector('input#deskfilename');
    var context = deskcanvas.getContext('2d');
    const constraints = {
      audio: false,
      video: {
        width: 320,
        height: 240
      }
    };
    let captureDesktop = null;

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia(constraints);
      window.stream = stream;
      deskvideo.srcObject = stream;
      deskvideo.play();
      context.drawImage(deskvideo, 0, 0, 320, 240);
    }
    catch (e) {
      errorMsgElement.innerHTML = `navigator.getDisplayMedia error:${e.toString()}`;
    }
    deskfname.value = "E" + this.state.exam.id.toString() + "_U" + this.state.user.id.toString() + "_D";
    console.log(deskfname.value);

    var blob, url;
    deskcanvas.toBlob(function (blob) {
      var newImg = document.getElementById('deskimageSrc'),
        url = URL.createObjectURL(blob);
      console.log(url);
      console.log(blob);
      newImg.src = url;

      var data = new FormData();
      var file = new File([blob], deskfname.value);
      //var file = new File([blob],filename.value);
      console.log(file);
      data.append('file', file);
      if (blob) {
        Http.post('user-api/usera', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            //errorMsgElement.innerHTML = res.data;
            deskfilename.value = res.data.split(' ', 1);
            console.log(deskfilename.value);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      //this.setState({profilePic:blob});
    });
    this.state.desk_pic = deskfilename.value;

  }

  async getExam(event) {
    event.preventDefault();
    var api = "exam-api/exam/" + this.state.exam_id;
    const response = await Http.get(api);
    console.log(response.data);
    saveToSessionStorage(SESSION_STORAGE_KEYS.exam1, response.data)
    // localStorage.setItem('exam1', JSON.stringify(response.data));
    this.setState({ ...this.state, isLoaded: true, exam: response.data });
  }
  /*handleSubmit(event) {
    event.preventDefault();
    this.setState({ exam_found: 1 })
    this.getExam();
  }*/
  handleAvailabilityChange = (event) => {
    var available = event.target.value;
    const errorMsgElement = document.querySelector('span#errorMsg');
    const mstatus = document.querySelector('input#mstatus');

    //remember if mirroring was ever turned on
    this.state.mirroring_state = available;
    mstatus.value = available
    errorMsgElement.innerHTML = available ? "Mirroring" : "not Mirroring";
    if (available)
      alert("Mirroring Detected");
  }

  _isMirroringOn = async()=>{
    const mstatus = document.querySelector('input#mstatus');
    const presUrls = ["https://monitorexam.com/newlogin",
      "https://monitorexam.net/newiregister"];

      isMirroringOn(presUrls)
      .then((availability)=>{
        mstatus.value = availability.value;
        availability.onChange = this.handleAvailabilityChange;
        this.setState({
          ...this.state,
          mirroring_state : availability.value
        })
      })
  }


  handleCapture = (event) => {
    const video = document.getElementById('video');
    const canvas = document.getElementById('canvas');
    //  const snap = document.getElementById("snap");
    const errorMsgElement = document.querySelector('span#errorMsg');
    const timeMsgElement = document.querySelector('span#timeMsg');
    const filename = document.querySelector('input#filename');
    const fname = document.querySelector('input#fname');
    const status = document.querySelector('input#status');

    this.setState({
      captured: true
    });
    // Draw image
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, 320, 240);
    var blob, url;
    fname.value = "E" + this.state.exam.id.toString() + "_U" + this.state.user.id.toString() + "_";
    console.log(fname.value);
    //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');

    canvas.toBlob(function (blob) {
      var newImg = document.getElementById('imageSrc'),
        url = URL.createObjectURL(blob);
      console.log(url);
      console.log(blob);
      newImg.src = url;

      var data = new FormData();
      var file = new File([blob], fname.value);
      //var file = new File([blob],filename.value);
      console.log(file);
      data.append('file', file);
      if (blob) {
        Http.defaults.baseURL = 'https://app.monitorexam.com/'
        Http.post('user-api/usera', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
            //errorMsgElement.innerHTML = res.data;
            filename.value = res.data.split(' ', 1);
            if (res.data.includes('not')) {
              status.value = "Away";
            }
            else {
              //get numFaces
              if (res.data.includes('Active')) {
                status.value = "Present";
              }
              else if (res.data.includes('Looking up'))
                status.value = "Looking up";
              else if (res.data.includes('Looking down'))
                status.value = "Looking down";
              else if (res.data.includes('Looking side'))
                status.value = "Looking side";
              else if (res.data.includes('More'))
                status.value = "Doubt";
              /*
                    const cdata = res.data.split(" ");
                  console.log(cdata[2]);
                  if (cdata[2] != 1)
                    status.value = "Doubt";*/
              console.log(status.value);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      //this.setState({profilePic:blob});
    });
    if (filename.value) {
      this.state.file = filename.value;
      this.state.live_pic = filename.value;
      this.state.status = status.value;
    }
    else {
      this.state.status = "Away";
    }
    if (this.state.minutes_left == 2) {        //reverse countdown in seconds
      var count = 50; //change this if you change frequency of handleCapture
      alert(count + " seconds left. Please submit your answers and logout");
    }
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
    }
    else { }
  }
  async environmentCheck() {
    const video = document.getElementById('video');
    const errorMsgElement = document.querySelector('span#errorMsg');
    //const cameraMsgElement = document.querySelector('span#cameraMsg');
    //const nwMsgElement = document.querySelector('span#nwMsg');
    //const baMsgElement = document.querySelector('span#baMsg');
    //Location
    navigator.geolocation.getCurrentPosition(this.showPosition);
    //GetIP
    Http.get('https://api.ipify.org?format=json')
      .then(res => {
        const ipnew = res.data.ip;
        console.log(ipnew);
        this.setState({
          ip: ipnew
        });
      });
    this.setState({
      network_state: navigator.onLine
    });
    //Network
    var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    connection.onChange = this.updateState;
    /*if (connection) {
        var speed = `None`;
        switch (connection.effectiveType) {
            case "4g":
                speed = "good";
                break;
            case "3g":
                speed = "fair";
                break;
            case "2g":
                speed = "slow";
                break;
            case "slow-2g":
                speed = "slow";
                break;
        }
        nwMsgElement.innerHTML = `Network speed is ` + connection.downlink + "Mbps";
    }*/
    var battery = navigator.battery || navigator.mozBattery || navigator.webkitBattery;
    //Battery
    if (navigator.getBattery) {
      var level = navigator.getBattery().then(function (battery) {
        //baMsgElement.innerHTML = `<img alt="battery" src="/i/battery.png" width="15%"> ...... &#10003  level ` + battery.level * 100 + '% ' + (battery.charging ? 'Charging' : 'Not Charging')
        //baMsgElement.innerHTML += `<progress value=` + battery.level * 100 + ` max="100" style={{color:"#6576ff"}} width=250px height=25px>%</progress>`;
        return battery.level * 100;
      }).then((result) => this.setState({ battery_state: true, battery_level: result }));
    } else {
      this.setState({
        battery_state: false
      });
      //baMsgElement.innerHTML = `<img  alt="battery"  src='/i/battery.png width="15%"/> ...... &#10005`
    }

    //Bluetooth
    /*
    navigator.bluetooth.requestDevice({
            acceptAllDevices: true
        })
        .then(function(device) {
            console.log(device.name);
        });
    */
    const constraints = {
      audio: false,
      video: {
        width: 320,
        height: 240
      }
    };


    // this.isMirroringOn();
   await this._isMirroringOn()
    if (this.state.exam.platform == "Zoom") return;
    //Cameraf
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints) || navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;;
      ;
      window.stream = stream;
      video.srcObject = stream;
      this.setState({
        camera_state: true
      });
      //cameraMsgElement.innerHTML = `Camera ...... &#10003`;
    } catch (e) {
      this.setState({
        camera_state: false
      });
      //cameraMsgElement.innerHTML = `Camera ...... &#10005`;
      //errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
    }
    //TODO: Desktop
    //Image Capture
    //Voice Capture
  }

  uploadAnswerSheet = (e) => {
    var data = new FormData();
    var tout
    const errorMsgElement = document.querySelector('span#ansMsg');
    const timeMsgElement = document.querySelector('span#timeMsg');
    const answerfname = document.querySelector('input#answerfname');
    const file = e.target.files[0];


    answerfname.value = "E" + this.state.exam.id.toString() + "_U" + this.state.user.id.toString() + "_excel.xlsx";
    const updatedFile = new File([file], answerfname.value, { type: file.type });

    //data.append('file', e.target.files[0], answerfname.value);
    data.append('file', updatedFile);
    //data.append('cred',this.state.user.cred);
    console.log(e.target.files[0]);
    this.setState({
      fileAnswerSheet: URL.createObjectURL(updatedFile)
    });
    console.log(this.state.fileAnswerSheet);
    var api = "exam-api/exama/" + this.state.user.id + "/" + this.state.exam.id;
    apiPut(api, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        var count = 120;

        this.state.status = "Completed";
        this.updateState();
        errorMsgElement.innerHTML = "Answer Sheet submitted <a href=http://d1bumj8k6ax755.cloudfront.net/uploads/" + res.data + ">View Here</a><br /> We will log you out after 2 minutes";
        clearInterval(this.timerID);
        clearInterval(this.timerCap);

        //Mark status as Done
        tout = setTimeout(() => { this.setState({ exam_status: "Done" }); }, 30000);
        //reverse countdown in seconds
        var t30 = setInterval(() => { count = count - 1; timeMsgElement.innerHTML = count + " seconds"; if (count < 0) clearInterval(t30); }, 1000);
      })
      .catch(function (error) {
        console.log(error);
        errorMsgElement.innerHTML = error;
      });



    //TODO Mark exam status as done         
  }


  async componentWillMount() {
    //Get ready for action
    /* Get data from localStorage*/
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
    const exam = getFromSessionStorage(SESSION_STORAGE_KEYS.exam1)
    const video = document.getElementById('video');
    const errorMsgElement = document.querySelector('span#errorMsg');
    var tout, minutes_left;

    if (user) {
      this.state.user = user;
      this.state.isLogged = true;
    }
    else if (this.props.user) {
      this.state.user = this.props.user;
      this.state.isLogged = true;
    }
    else
      console.log("User not available");
    //TODO: May be add login again to above

    if (exam)
      this.state.exam = exam;
    else if (this.props.exam)
      this.state.exam = this.props.exam;
    else {
      console.log("Exam not available");
      this.state.exam_found = 0;
    }

    /************************************************/
    //        console.log(this.state.user);
    //        console.log(this.state.exam
    //        the variable this.state.exam_status will be updating every minute
    /************************************************/
    if (this.state.exam_found !== 0) {

      //Time passed since exam and left
      const date = Date.parse(this.state.exam.date);
      const time = Date.parse("1970-01-01T" + this.state.exam.start_time.toString() + "Z");
      var now = Date.now();
      var passed = (now - date - time) / 60000;

      if (this.state.exam.duration > passed) {
        //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
        minutes_left = Math.floor(this.state.exam.duration - passed);
        console.log(minutes_left);
        this.state.minutes_left = minutes_left;

        //this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";
        this.state.exam_status = (minutes_left > this.state.exam.duration) ? "Not Started" : ((minutes_left <= 0) ? "Done" : "Ongoing");
        //TODO: Minutes can be calculated from here. Put timeout or time interval?
        if (this.state.exam_status != "Done") {
          //   tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
          tout = setInterval(() => { this.state.exam_status = (this.state.minutes_left-- > this.state.exam.duration) ? "Not Started" : ((minutes_left < 0) ? "Done" : "Ongoing") }, 60000);
        }
        else {
          clearInterval(tout);
          clearInterval(this.timerID);
          clearInterval(this.timerCap);
        }
        /* View PDF code the api-id is via manu...gmail*/
        if (exam.platform === "Offline") {
          document.addEventListener("adobe_dc_view_sdk.ready", function () {
            (function (exam) {
              console.log(exam);

              window.adobeDCView = new window.AdobeDC.View({ clientId: "0eb4d90958fe48ebbdd31257add46610", divId: "adobe-dc-view" });
              //window.adobeDCView = new window.AdobeDC.View({clientId: "74b45275061347f88822164d43063adb", divId: "adobe-dc-view"});
              //window.adobeDCView = new window.AdobeDC.View({clientId: "1224c4840c8345aba5aeaece3d4d1819", divId: "adobe-dc-view"});
              window.adobeDCView.previewFile({
                content: {
                  location:
                    { url: exam.link }
                },
                metaData: { fileName: exam.platform }
              },
                {
                  defaultViewMode: "FIT_WIDTH", showDownloadPDF: false, showPrintPDF: false
                });
            })(exam);
          });
        }
        else {
          let link = this.state.exam.link.replace("Student+Name", this.state.user.first_name + "+" + this.state.user.last_name);
          link = link.replace("Student@Email.com", this.state.user.email);
          link = link.replace("1234", this.state.user.id);
          this.state.exam.link = link.replace("1111", this.state.exam.id);
        }
      }//check if test is still not over
      else {
        console.log("Exam is already over");
        errorMsgElement.innerHTML = "Exam is already over";
        alert("Exam is already over");
      }
    }
  }


  toLocal = (utctime) => {
    var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z"));
    x += this.state.user.timezone * 60000; return (x.toLocaleString().slice(16, 24));
  }
  toLocalTime = () => {
    console.log(this.props.exam.date);
    var x = new Date(this.props.exam.date + "T" + this.props.exam.start_time + "Z");
    return x.toLocaleTimeString();
  }
  handleChange = (event) => {
    this.setState({
      type: event.target.value
    });

    //TODO remove the code below
    // if (this.state.exam.platform == "google") {
    //   this.setState({
    //     testSrc: "https://docs.google.com/forms/d/e/1FAIpQLSd91heY26bYYFLz1INIUZ0w7hitBS4ficHFhfEDvHcGE-4XSA/viewform?embedded=true"
    //   });
    // } else if (this.state.exam.platform == "quizzizz") {
    //   this.setState({
    //     testSrc: "https://quizizz.com/join/quiz/5f6deb133dce58001c1794cc/start"
    //   });
    // } else if (this.state.exam.platform == "microsoft") {
    //   this.setState({
    //     testSrc: "https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__rBlM09UMTFRUlU1WDE5NkcyQzlNWlNYTlBTTzNQVC4u&embed=true"
    //   })
    // } else if (this.state.exam.platform == "offline") {
    //   this.setState({
    //     testSrc: "https://docs.google.com/forms/d/e/1FAIpQLSd91heY26bYYFLz1INIUZ0w7hitBS4ficHFhfEDvHcGE-4XSA/viewform?embedded=true"
    //   })
    // }

  }

  
  assertionVerification = () => {
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
    getAssertion(user)
      .then((credential) => {
        verifyAssertion(credential)
          .then((res) => {
            this.setState({ status: true })
          }).catch((err) => {
            this.setState({ status: false })
          })
      })
      .catch((err) => { console.log(err, 'ERR IN GET ASSERTIONS=------') })
  }

  async componentDidMount() {

    const video = document.getElementById('video');
    const errorMsgElement = document.querySelector('span#errorMsg');
    const constraints = {
      audio: false,
      video: {
        width: 320,
        height: 240
      }
    };
    //this.getAssertion();
    document.addEventListener('keydown', this.keydownHandler);
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints) || navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;;
      ;
      window.stream = stream;
      video.srcObject = stream;
      video.play();
      this.state.camera_state = true;
      //video.requestPictureInPicture();
      //TODO: May have to remove later
      this.handleCapture();
    } catch (e) {
      this.state.camera_state = false;
      //errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
    }

    this.timerID = setInterval(() => {
      this.updateState();
    }, 30000);
    this.timerCap = setInterval(() => {
      this.handleCapture();
    }, 51000);
    this.timerFIDO = setInterval(() => { this.assertionVerification() }, 600000);



    }

  async componentDidUpdate(){
    const token= sessionStorage.getItem('accessToken')
    this.sendTokenToIframe(token);
  }

  toggleUserMenu() {
    this.setState({ 
      ...this.state,
      userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    //  this.setState({ menu: !this.state.menu });
  }

  _toggleFullScreen() { toggleFullScreen(".exam-workspace") }
  _toggleFullScreenOffline() { toggleFullScreenOffline("adobe-dc-view") }

  render() {
    if (this.state.isLogged) {
      if (this.state.status == "Completed" || this.state.exam_status == "Done" || this.state.minutes_left <= 0) {
        const show = this.state.menu ? "active" : "noactive";
        const showMenu = this.state.menu ? "content-active" : "noactive";
        const usershow = this.state.userMenu ? "show" : "";
console.log('stat', this.state.status,this.state.exam_status,this.state.minutes_left )
        return (

          <div>
            <div id="test">
              <meta charSet="utf-8" />
              <meta name="author" content="Softnio" />
              <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
              <meta name="description" content="@@page-discription" />
              {/* Fav Icon  */}
              <link rel="shortcut icon" href="images/favicon.png" />
              {/* Page Title  */}
              <title>Current Exam | Monitor Exam</title>
              {/* StyleSheets  */}
              <link rel="stylesheet" href="assets/css/style.css" />
              <link rel="stylesheet" href="assets/css/custom.css" />
              <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
              <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                  {/* wrap @s */}
                  <div className="nk-wrap ">
                    <Header user={this.state.user || getFromSessionStorage(SESSION_STORAGE_KEYS.user)} />
                    {/* main header @e */}
                    {/* content @s */}
                  
                    <div className="nk-content" style={{paddingTop:70}}>
                      <div className="container wide-xl">
                        <div className="nk-content-inner">
                          <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                            <div className="nk-sidebar-menu" data-simplebar>
                              {/* Menu */}
                              <ul className="nk-menu">
                                <li className="nk-menu-heading d-none">
                                  <h6 className="overline-title">Menu</h6>
                                </li>
                                <li className={"nk-menu-item " + ((this.state.user.role == "invig") ? "active" : "noactive")}>
                                  <a href="\newdashboard" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                                    <span className="nk-menu-text">Student Dashboard</span>
                                  </a>
                                  <ul className="nk-menu-sub">
                                    <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                                    <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
                                  </ul>
                                </li>
                                <li className="nk-menu-item active">
                                  <a href="\testdash" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                                    <span className="nk-menu-text">Exam Dashboard</span>
                                  </a>
                                  <ul className="nk-menu-sub">
                                    <li className="nk-menu-item"><a href="\testdash" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
                                    <li className="nk-menu-item"><a href="\upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                                    <li className="nk-menu-item active"><a href="\flexi" className="nk-menu-link"><span className="nk-menu-text">Flexi Exams</span></a></li>
                                    <li className="nk-menu-item"><a href="\unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
                                    <li className="nk-menu-item"><a href="\pastexamdash" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
                                  </ul>
                                </li>
                                <li className="nk-menu-item">
                                  <a href="\newlogin" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                                    <span onClick={() => sessionStorage.clear()} className="nk-menu-text">Log Out</span>
                                  </a>
                                </li>
                              </ul>
                            </div>{/* .nk-sidebar-menu */}
                            <div className="nk-aside-close">
                              <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                            </div>{/* .nk-aside-close */}
                          </div>{/* .nk-aside */}
                          <div className="nk-content-body">
                            <div className="nk-content-wrap">
                              <div className="nk-block-head nk-block-head-lg">
                                <div className="nk-block-between-md g-4">
                                  <div className="nk-block-head-content" onVisibilityChange={this.updateState} >
                                    <h2 className="nk-block-title">{this.state.exam.name}</h2>
                                    <p className="attendee-name mb-1">ID: STUD00{this.state.user.id}</p>
                                    <p className="attendance-marked">Status: Attendance Marked Successfully</p>
                                    <p><span id="timeMsg" style={{ display: 'block' }}> </span></p>
                                    <div className="nk-block-des">
                                    </div>
                                  </div>
                                  <div className="nk-block-head-content" onVisibilityChange={() => alert("Please focus on your exam")}>
                                    <div className="row text-right align-center m-mb-5">
                                      <input type="hidden" name="filename" id="filename" />
                                      <input type="hidden" name="status" id="status" />
                                      <input type="hidden" name="fname" id="fname" />
                                      <input type="hidden" name="mstatus" id="mstatus" />
                                      <div className="col-sm-3 col-3" >
                                        <p class="attendee-name">{this.state.user.first_name} {this.state.user.last_name}</p>
                                      </div>
                                      <div className="col-sm-9 col-9" >
                                        <video id="video" width="320px" height="240px" style={{ display: this.state.exam.platform == "Zoom" ? "none" : "block" }} playsInline autoPlay > </video>
                                        <img src={this.state.file} id='imageSrc' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', backgroundColor: 'lightGray', display: 'none' }}></img >
                                        <canvas id="canvas" width="320px" height="240px" style={{ display: this.state.captured ? "none" : "none" }} > </canvas>
                                        <p><span id="errorMsg"></span></p>
                                        <p><span id="timeMsg" style={{ display: 'block' }}> </span></p>
                                      </div>
                                      <div style={{ display: "none" }}>
                                        <input type="hidden" name="deskfilename" id="deskfilename" />
                                        <input type="hidden" name="deskfname" id="deskfname" />

                                        <div className="video-wrap" >
                                          < video id="deskvideo" width="320px" height="240px" style={{ display: this.state.captured ? "block" : "block" }} playsInline autoPlay > </video>
                                          <img src={this.state.deskfile} id='deskimageSrc' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', backgroundColor: 'lightGray', display: 'none' }}></img >
                                          < canvas id="deskcanvas" width="320px" height="240px" style={{ display: this.state.captured ? "none" : "none" }} > </canvas>
                                        </div>
                                        <Button onClick={this.captureDesktop}>Capture Desktop</Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{/* .nk-block-head */}
                              <Alert variant='success' dismissable>
                                <Alert.Heading>Exam is submitted.  </Alert.Heading>
                                <p> Please connect with <a href="mailto:care@monitorexam.com">care@monitorexam.com</a> for any clarifications </p>
                                <p>Logout by clicking the button below</p>
                              </Alert>
                              <p> Your exam has been submitted successfully. Please logout by clicking the button below</p>
                              <a href="/newLogin" className="btn btn-lg btn-primary" onClick={() => sessionStorage.clear()} >Logout</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* content @e */}
                    </div>
                   
                    {/* wrap @e */}
                  </div>
                  {/* main @e */}
                </div>
                {/*footer*/}
              </div>
            </div>
          </div >);
      }
      else if (this.state.exam_found == 0) {
        return (
          <div>
            <form onSubmit={this.handleSubmit}>
              {/*<label for="exam_id"> Please enter exam id received in mail </label>
      <input placeholder="XXXXXXXX" id="exam_id" name="exam_id" onClick={this.updateState}> </input>*/}
              <label for="exam_id"> Please enter exam id received in mail </label>
              <input type="text" id="exam_id" name="exam_id" placeholder="XXXXXXXXX" onChange={this.handleInput} />
              <Button type="submit" onClick={this.getExam}>Start Test</Button>
            </form>
          </div>);
      }
      else {
        const show = this.state.menu ? "active" : "noactive";
        const showMenu = this.state.menu ? "content-active" : "noactive";
        const usershow = this.state.userMenu ? "show" : "";

        return (
          <div id="test">
            <meta charSet="utf-8" />
            <meta name="author" content="Softnio" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="description" content="@@page-discription" />
            {/* Fav Icon  */}
            <link rel="shortcut icon" href="images/favicon.png" />
            {/* Page Title  */}
            <title>Current Exam | Monitor Exam</title>
            {/* StyleSheets  */}
            <link rel="stylesheet" href="assets/css/style.css" />
            <link rel="stylesheet" href="assets/css/custom.css" />
            <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
            <div className="nk-app-root">
              {/* main @s */}
              <div className="nk-main ">
                {/* wrap @s */}
                <div className="nk-wrap ">
                  <div className="nk-header nk-header-fixed is-light">
                    <div className="container-lg wide-xl">
                      <div className="nk-header-wrap">
                        <div className="nk-header-brand">
                          <a href="#" className="logo-link">
                            <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" alt="logo-dark" />
                          </a>
                        </div>{/* .nk-header-brand */}
                        <div className="nk-header-tools">
                          <ul className="nk-quick-nav">
                            {/* <li onClick={()=>{}} className={"dropdown user-dropdown " + usershow}>
                              <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                                <div className="icon-status icon-status-info">
                                  <em className="icon ni ni-bell" />
                                </div>
                              </a>
                              <div className={"dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1 " + usershow}>
                                <div className="dropdown-head">
                                  <span className="sub-title nk-dropdown-title">Notifications</span>
                                  <a href="#">Mark All as Read</a>
                                </div><div className="dropdown-body">
                                  <div className="nk-notification">
                                    <div className="nk-notification-item dropdown-inner">
                                      <div className="nk-notification-icon">
                                        <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                                      </div>
                                      <div id="notification" className="nk-notification-content">
                                        <div className="nk-notification-text" id="ansMsgNot">
                                          You are appearing for your<span>exam</span>
                                        </div>
                                        <div className="nk-notification-time">
                                          {this.state.exam.duration - this.state.minutes_left} minutes ago
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="dropdown-foot center">
                                  <a href="#">View All</a>
                                </div>
                              </div>
                            </li> */}
                            <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                              <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                                <div className="user-toggle">
                                  <div className="user-avatar sm">
                                    <em className="icon ni ni-user-alt" />
                                  </div>
                                  <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                                </div>
                              </a>
                              <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                  <div className="user-card">
                                    <div className="user-avatar">
                                      <span>AB</span>
                                    </div>
                                    <div className="user-info">
                                      <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                      <span className="sub-text">{this.state.user.email}</span>
                                    </div>
                                    <div className="user-action">
                                      <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                    </div>
                                  </div>
                                </div>
                                <div className="dropdown-inner">
                                  <ul className="link-list">
                                    <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                    <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                    <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                                  </ul>
                                </div>
                                <div className="dropdown-inner">
                                  <ul className="link-list">
                                    <li><a href="/newLogin"><em className="icon ni ni-signout" /><span onClick={() => sessionStorage.clear()}>Sign out</span></a></li>
                                  </ul>
                                </div>
                              </div>
                            </li>{/* .dropdown */}
                            <li onClick={this.toggleMenu} className={this.state.menu ? "d-lg-none" : "d-none"}>
                              <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + (this.state.menu ? "active" : "")} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                            </li>
                          </ul>{/* .nk-quick-nav */}
                        </div>{/* .nk-header-tools */}
                      </div>{/* .nk-header-wrap */}
                    </div>{/* .container-fliud */}
                  </div>
                  {/* main header @e */}
                  {/* content @s */}
                  <div className="nk-content ">
                    <div className="container wide-xl">
                      <div className="nk-content-inner">
                        <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                          <div className="nk-sidebar-menu" data-simplebar>
                            {/* Menu */}
                            <ul className="nk-menu">
                              <li className="nk-menu-heading d-none">
                                <h6 className="overline-title">Menu</h6>
                              </li>
                              <li className={"nk-menu-item " + ((this.state.user.role == "invig") ? "active" : "noactive")}>
                                <a href="\newdashboard" className="nk-menu-link">
                                  <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                                  <span className="nk-menu-text">Student Dashboard</span>
                                </a>
                              </li>
                              <li className="nk-menu-item active">
                                <a href="\testdash" className="nk-menu-link">
                                  <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                                  <span className="nk-menu-text">Exam Dashboard</span>
                                </a>
                                <ul className="nk-menu-sub">
                                  <li className="nk-menu-item"><a href="\testdash" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
                                  <li className="nk-menu-item"><a href="\upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                                  <li className="nk-menu-item"><a href="\unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
                                  <li className="nk-menu-item"><a href="\pastexamdash" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
                                </ul>
                              </li>
                              <li className="nk-menu-item">
                                <a href="\newlogin" className="nk-menu-link">
                                  <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                                  <span onClick={() => sessionStorage.clear()} className="nk-menu-text">Log Out</span>
                                </a>
                              </li>
                            </ul>
                          </div>{/* .nk-sidebar-menu */}
                          <div className="nk-aside-close">
                            <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                          </div>{/* .nk-aside-close */}
                        </div>{/* .nk-aside */}
                        <div className="nk-content-body">
                          <div className="nk-content-wrap">
                            <div className="nk-block-head nk-block-head-lg">
                              <div className="nk-block-between-md g-4">
                                <div className="nk-block-head-content" onVisibilityChange={this.updateState} >
                                  {/* <h2 className="nk-block-title">{this.state.exam.name}</h2>
                                <p className="attendee-name mb-1">ID: STUD00{this.state.user.id}</p>
	                        <p className="attendance-marked">Status: Attendance Marked Successfully</p>
                                <p><span id="timeMsg" style={{ display: 'block' }}> </span></p>i*/}
                                  <div className="nk-block-des">
                                  </div>
                                </div>
                                {/*<div className="nk-block-head-content" onVisibilityChange={() => alert("Please focus on your exam")}>
                                <div className="row text-right align-center m-mb-5">
                                  <input type="hidden" name="filename" id="filename" />
                                  <input type="hidden" name="status" id="status" />
                                  <input type="hidden" name="fname" id="fname" />
                                  <input type="hidden" name="mstatus" id="mstatus" />
                                  <div className="col-sm-3 col-3" >
                                    <p>{this.state.user.first_name} {this.state.user.last_name}</p>
                                  </div>
                                  <div className="col-sm-9 col-9" >
                                    <video id="video" width="320px" height="240px" playsInline autoPlay > </video>
                                    <img src={this.state.file} id='imageSrc' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', backgroundColor: 'lightGray', display: 'none' }}></img >
                                    <canvas id="canvas" width="320px" height="240px" style={{ display: this.state.captured ? "none" : "none" }} > </canvas>
                                  </div>
                                  <div style={{ display: "none" }}>
                                    <input type="hidden" name="deskfilename" id="deskfilename" />
                                    <input type="hidden" name="deskfname" id="deskfname" />
                                    <div className="video-wrap" >
                                      < video id="deskvideo" width="320px" height="240px" style={{ display: this.state.captured ? "block" : "block" }} playsInline autoPlay > </video>
                                      <img src={this.state.deskfile} id='deskimageSrc' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', backgroundColor: 'lightGray', display: 'none' }}></img >
                                      < canvas id="deskcanvas" width="320px" height="240px" style={{ display: this.state.captured ? "none" : "none" }} > </canvas>
                                    </div>
                                    <Button onClick={this.captureDesktop}>Capture Desktop</Button>
                                  </div>
                                  <p><span id="errorMsg"></span></p>
                                  <p><span id="timeMsg" style={{ display: 'block' }}> </span></p>
                                </div>
                              </div>*/}
                              </div>
                            </div>{/* .nk-block-head */}
                            <div className="nk-block">
                              <div className="card card-bordered">
                                <div className="exam-workspace m-200">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <h6 className="text-primary pl-4">{this.state.exam.name}</h6>
                                    </div>
                                    <div className="col-sm-4">
                                      <progress className="d-none" value={this.state.exam.duration - this.state.minutes_left} max={this.state.exam.duration} style={{ color: "#6576ff", backgroundColor: "#f5f6fa" }} width="350px" height="25px">{this.state.exam.duration - this.state.minutes_left}</progress>
                                      <div class="progress progress-lg">
                                        <div class="progress-bar" data-progress={this.state.exam.duration - this.state.minutes_left}
                                          style={{ width: `${((this.state.exam.duration - this.state.minutes_left) / this.state.exam.duration) * 100}%` }}

                                        >{this.state?.minutes_left} minutes</div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className="text-right" style={{ color: "#6576ff" }}>
                                        {console.log(
                                          'minutes', this.state.minutes_left
                                        )}
                                        Time left : <CountdownTimer initialMinutes={this.state.minutes_left} />
                                        {/*Time left : {this.state.minutes_left} minutes<span id="timer" />*/}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row ">
                                    <div className="col-sm-8">
                                      <iframe id="exam_frame" ref={this.iframeRef} src={this.state.exam.link} onContextMenu={() => false} width="100%" height="800px" frameBorder={0} marginHeight={0} marginWidth={0} style={{ display: (this.state.exam.platform != "Offline" ? "" : "") }} className="question-screen" allowfullscreen>Loading…</iframe>
                                      <div id="dobe-dc-view" onContextMenu={() => false} style={{ height: "0px", display: (this.state.exam.platform != "Offline" ? "none" : "block") }}></div>
                                      <a onClick={this._toggleFullScreenOffline} className="see-full-screen" style={{ display: (this.state.exam.platform == "Offline" ? "block" : "none"), textAlign: "center", margin: "20px" }}> See Full Screen</a>
                                      <a onClick={this._toggleFullScreen} className="see-full-screen" style={{ display: (this.state.exam.platform != "Offline" ? "block" : "none"), textAlign: "center", margin: "20px" }}> See Full Screen</a>
                                      <form onSubmit={this.handleSubmit} className="mt-4 noactive">
                                        <div className="col-sm-12 form-group">
                                          <input type="hidden" name="answerfname" id="answerfname" />
                                          <div className="upload-zone">
                                            <div className="dz-message" data-dz-message>
                                              {/*<span className="dz-message-text">Drag and drop file</span>
                                            <span className="dz-message-or">or</span>
                                            <a className="btn btn-primary">SELECT</a>*/}
                                              <label className="btn btn-md btn-secondary"
                                                id="snap"
                                                for="uploadAnswerSheet"
                                                style={
                                                  {
                                                    textAlign: "center",
                                                    backgroundColor: "#6576ff",
                                                    color: "white",
                                                    borderRadius: "4px",
                                                    paddingTop: "10px",
                                                    align: "right",
                                                    border: "none",
                                                    margin: '2px',
                                                    padding: ".375rem",
                                                    fontSize: "1rem",
                                                    lineHeight: "1.5",
                                                    display: this.state.exam.platform == "Offline" ? "block" : "block"
                                                  }
                                                }
                                                size="md"
                                                required ><i className="ni ni-upload" /> Upload Answer Sheet </label>
                                              <input id="uploadAnswerSheet" type='file' style={{ display: this.state.exam.platform == "Offline" ? "block" : "block" }} name='fileAS' onChange={this.uploadAnswerSheet} />
                                              <p><span id="ansMsg">The Exam will be auto-submitted two minutes after uploading the file here</span></p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-12 form-group text-center" style={{ display: this.state.minutes_left < 2 ? "block" : "none" }}>
                                          <button type="" className="btn btn-lg btn-primary" onClick={() => sessionStorage.clear()} >Logout</button>
                                        </div>
                                      </form>
                                    </div>
                                    <input type="hidden" name="filename" id="filename" />
                                    <input type="hidden" name="status" id="status" />
                                    <input type="hidden" name="fname" id="fname" />
                                    <input type="hidden" name="mstatus" id="mstatus" />
                                    <div className="col-sm-4 col-4" >
                                      <video id="video" width="320px" height="240px" playsInline autoPlay > </video>
                                      <img src={this.state.file} id='imageSrc' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', backgroundColor: 'lightGray', display: 'none' }}></img >
                                      <canvas id="canvas" width="320px" height="240px" style={{ display: this.state.captured ? "none" : "none" }} > </canvas>
                                      <MinChat uuid={this.state.user.id + "monitorexam_com"} />
                                    </div>
                                    <div style={{ display: "none" }}>
                                      <input type="hidden" name="deskfilename" id="deskfilename" />
                                      <input type="hidden" name="deskfname" id="deskfname" />
                                      <div className="video-wrap" >
                                        < video id="deskvideo" width="320px" height="240px" style={{ display: this.state.captured ? "block" : "block" }} playsInline autoPlay > </video>
                                        <img src={this.state.deskfile} id='deskimageSrc' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', backgroundColor: 'lightGray', display: 'none' }}></img >
                                        < canvas id="deskcanvas" width="320px" height="240px" style={{ display: this.state.captured ? "none" : "none" }} > </canvas>
                                      </div>
                                      <Button onClick={this.captureDesktop}>Capture Desktop</Button>
                                    </div>
                                    <p><span id="errorMsg"></span></p>
                                    <p><span id="timeMsg" style={{ display: 'block' }}> </span></p>
                                    <div className="col-sm-4">
                                      {/*chat box*/}
                                      {/* chat box end*/}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* footer @e */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* content @e */}
                </div>
                {/* wrap @e */}
              </div>
              {/* main @e */}
            </div>
            {/*footer*/}
            {/* app-root @e */}
            {/* JavaScript */}
          </div>
        );
      }
    }
    else {
      return (<NewLogin />);
    }
  }
}

export default NewTest








// getAssertion = () => {
//   if (window.innerHeight === window.screen.height && window.innerWidth === window.screen.width) {
//     console.log('Browser is in fullscreen mode');
//   } else {
//     alert('You are not in fullscreen mode. Press F11 to enhance your exam experience');
//   }
//   if (!this.state.user.fido_auth_enabled)
//     return;
//   Http.get('/user/' + this.state.user.email + '/exists', {}, null, 'json').then(response => {
//     console.log(response);
//   })
//     .then(() => {

//       //var user_verification = $('#select-verification').find(':selected').val();            
//       //            //var txAuthSimple_extension = $('#extension-input').val();
//       //
//       Http.get('/assertion/' + this.state.user.email + '?userVer=required&txAuthExtension=', {
//         userVer: 'required',
//         txAuthExtension: ''
//       }, null, 'json')
//         .then(makeAssertionOptions => {
//           console.log("Assertion Options:");
//           console.log(makeAssertionOptions);
//           makeAssertionOptions.data.publicKey.challenge = this.bufferDecode(makeAssertionOptions.data.publicKey.challenge);
//           makeAssertionOptions.data.publicKey.allowCredentials.forEach(listItem => {
//             listItem.id = this.bufferDecode(listItem.id)
//           });
//           navigator.credentials.get({
//             publicKey: makeAssertionOptions.data.publicKey
//           })
//             .then(credential => {
//               this.verifyAssertion(credential);
//             }).catch(err => {
//               console.log(err.name);
//               alert("Verification failed");
//             });
//         });
//     })
//     .catch(error => {
//       if (!error.exists) {
//         alert("User not found, try registering one first!");
//       }
//       return;
//     });
// }


// verifyAssertion = (assertedCredential) => {
//   //       // Move data into Arrays incase it is super long
//   console.log('calling verify')
//   let authData = new Uint8Array(assertedCredential.response.authenticatorData);
//   let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
//   let rawId = new Uint8Array(assertedCredential.rawId);
//   let sig = new Uint8Array(assertedCredential.response.signature);
//   let userHandle = new Uint8Array(assertedCredential.response.userHandle);
//   Http.post('/assertion',
//     {
//       id: assertedCredential.id,
//       rawId: this.bufferEncode(rawId),
//       type: assertedCredential.type,
//       response: {
//         authenticatorData: this.bufferEncode(authData),
//         clientDataJSON: this.bufferEncode(clientDataJSON),
//         signature: this.bufferEncode(sig),
//         userHandle: this.bufferEncode(userHandle),
//       },
//     })
//     .then(response => {
//       console.log(response);
//       this.setState({ status: true });
//       localStorage.setItem('user', JSON.stringify(this.state.user));
//       alert("Verification Completed");
//     })
//     .catch(error => {
//       console.log(error);
//       this.setState({ status: false });
//       alert("Verification Failed");
//     });
// }

// bufferEncode(value) {
//   // return Buffer.from(value).toString('base64');
//   return Base64js.fromByteArray(value)
//     .replace(/\+/g, "-")
//     .replace(/\//g, "_")
//     .replace(/=/g, "");
// }
// bufferDecode(value) {
//   return Uint8Array.from(atob(value), c => c.charCodeAt(0));
// }








// toggleFullScreen() {
//   var elem = document.querySelector(".exam-workspace");

//   if (!document.fullscreenElement) {
//     elem.requestFullscreen().catch(err => {
//       alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
//     });
//   } else {
//     document.exitFullscreen();
//   }
// }

// toggleFullScreenOffline() {
//   var elem = document.getElementById("adobe-dc-view");

//   if (!document.fullscreenElement) {
//     elem.requestFullscreen().catch(err => {
//       alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
//     });
//   } else {
//     document.exitFullscreen();
//   }
// }




// isMirroringOn() {
//   //TODO: These are not valid URLs
//   var presUrls = ["https://monitorexam.com/newlogin",
//     "https://monitorexam.net/newiregister"];
//   //const mMsgElement = document.querySelector('span#mMsg');
//   const mstatus = document.querySelector('input#mstatus');

//   var request = new PresentationRequest(presUrls);
//   this.state.mirroring_state = request.getAvailability().then(function (availability) {
//     // availability.value may be kept up-to-date by the controlling UA as long
//     // as the availability object is alive. It is advised for the web developers
//     // to discard the object as soon as it's not needed.
//     console.log(availability.value);
//     mstatus.value = availability.value;
//     availability.onChange = this.handleAvailabilityChange;
//     return availability.value;

//   }).catch(function () {
//     // Availability monitoring is not supported by the platform, so discovery of
//     // presentation displays will happen only after request.start() is called.
//     // Pretend the devices are available for simplicity; or, one could implement
//     // a third state for the button.
//     //this.handleAvailabilityChange(true);
//     console.log("getAvailability returned error");
//   });
// }
